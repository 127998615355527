export const config = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  // BASE_URL: '/api',
  ENV: process.env.REACT_APP_NODE_ENV,
  UPTIME_MONITOR_URL: process.env.REACT_APP_UPTIME_MONITOR_URL,
  ACCESS_TOKEN_KEY: 'cm_agent_token',
  REFRESH_TOKEN_KEY: 'cm_agent_refresh',
  error: {
    '50*': 'Error del servidor',
    '40*': 'Error en el cliente'
  },
  SD: {
    INSUFICIENT_CREDITS: 'FichasInsuficientes',
    DEPOSIT_STATUS: {
      /** Created by user, yet to be verified. */
      PENDING: 'pending',
      /** Unsucsessful verification attempt was made */
      UNVERIFIED: 'unverified',
      /** Found and verified at alquimia or bank. */
      VERIFIED: 'verified',
      /** Deleted by agent */
      DELETED: 'deleted'
    },
    DEPOSIT_EVENT: 'deposit',
    COIN_TRANSFER_EVENT: 'coin-transfer'
  }
}

export enum PLAYER_STATUS {
  ACTIVE = 'ACTIVO',
  BANNED = 'BLOQUEADO'
}
export enum PAYMENT_STATUS {
  REQUESTED = 'PEDIDO',
  PENDING = 'PENDIENTE AUTORIZAR',
  PROCESSING = 'EN PROCESO',
  COMPLETED = 'LIQUIDADA',
  ERROR = 'error'
}
export enum COIN_TRANSFER_STATUS {
  PENDING = 'pending',
  COMPLETED = 'completed'
}

export enum GLOBAL_SWITCH_STATE {
  ON = 'on',
  OFF = 'off'
}

export enum BLACKLIST_METHOD {
  ADD = 'add',
  REMOVE = 'remove'
}
