import { MouseEventHandler } from 'react'
import { Span } from './styled'
import { XIcon } from 'components/icons/x'

export function CloseBtn({
  closeToast
}: {
  closeToast: MouseEventHandler<HTMLSpanElement>
}) {
  return <Span onClick={closeToast}><XIcon width={20} height={20} /></Span>
}
