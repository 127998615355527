const bankList = [
  { value: '40138', name: 'ABC CAPITAL', category: 'Más Usados' },
  { value: '40133', name: 'ACTINVER', category: 'Otros Bancos' },
  { value: '40062', name: 'AFIRME', category: 'Otros Bancos' },
  { value: '90706', name: 'ARCUS', category: 'Bancos Virtuales' },
  { value: '90659', name: 'ASP INTEGRA OPC', category: 'Bancos Virtuales' },
  { value: '40128', name: 'AUTOFIN', category: 'Otros Bancos' },
  { value: '40127', name: 'AZTECA', category: 'Otros Bancos' },
  { value: '37166', name: 'BaBien', category: 'Otros Bancos' },
  { value: '40030', name: 'BAJIO', category: 'Otros Bancos' },
  { value: '40002', name: 'BANAMEX', category: 'Más Usados' },
  { value: '40154', name: 'BANCO COVALTO', category: 'Otros Bancos' },
  { value: '37006', name: 'BANCOMEXT', category: 'Otros Bancos' },
  { value: '40137', name: 'BANCOPPEL', category: 'Más Usados' },
  { value: '40160', name: 'BANCO S3', category: 'Otros Bancos' },
  { value: '40152', name: 'BANCREA', category: 'Otros Bancos' },
  { value: '37019', name: 'BANJERCITO', category: 'Otros Bancos' },
  { value: '40147', name: 'BANKAOOL', category: 'Bancos Virtuales' },
  { value: '40106', name: 'BANK OF AMERICA', category: 'Otros Bancos' },
  { value: '40159', name: 'BANK OF CHINA', category: 'Otros Bancos' },
  { value: '37009', name: 'BANOBRAS', category: 'Otros Bancos' },
  { value: '40072', name: 'BANORTE', category: 'Más Usados' },
  { value: '40058', name: 'BANREGIO', category: 'Más Usados' },
  { value: '40060', name: 'BANSI', category: 'Otros Bancos' },
  { value: '2001', name: 'BANXICO', category: 'Otros Bancos' },
  { value: '40129', name: 'BARCLAYS', category: 'Otros Bancos' },
  { value: '40145', name: 'BBASE', category: 'Otros Bancos' },
  { value: '40012', name: 'BBVA MEXICO', category: 'Más Usados' },
  { value: '40112', name: 'BMONEX', category: 'Otros Bancos' },
  { value: '90677', name: 'CAJA POP MEXICA', category: 'Otros Bancos' },
  { value: '90683', name: 'CAJA TELEFONIST', category: 'Otros Bancos' },
  { value: '90630', name: 'CB INTERCAM', category: 'Otros Bancos' },
  { value: '40124', name: 'CBM BANCO', category: 'Otros Bancos' },
  { value: '40143', name: 'CIBANCO', category: 'Otros Bancos' },
  { value: '90631', name: 'CI BOLSA', category: 'Otros Bancos' },
  { value: '90901', name: 'CLS', category: 'Otros Bancos' },
  { value: '90903', name: 'CoDi Valida', category: 'Otros Bancos' },
  { value: '40130', name: 'COMPARTAMOS', category: 'Otros Bancos' },
  { value: '40140', name: 'CONSUBANCO', category: 'Otros Bancos' },
  { value: '90652', name: 'CREDICAPITAL', category: 'Bancos Virtuales' },
  { value: '90688', name: 'CREDICLUB', category: 'Bancos Virtuales' },
  { value: '90680', name: 'CRISTOBAL COLON', category: 'Otros Bancos' },
  { value: '90723', name: 'Cuenca', category: 'Bancos Virtuales' },
  { value: '40151', name: 'DONDE', category: 'Otros Bancos' },
  { value: '90616', name: 'FINAMEX', category: 'Otros Bancos' },
  { value: '90634', name: 'FINCOMUN', category: 'Otros Bancos' },
  { value: '90689', name: 'FOMPED', category: 'Otros Bancos' },
  { value: '90699', name: 'FONDEADORA', category: 'Otros Bancos' },
  { value: '90685', name: 'FONDO (FIRA)', category: 'Otros Bancos' },
  { value: '90601', name: 'GBM', category: 'Otros Bancos' },
  { value: '37168', name: 'HIPOTECARIA FED', category: 'Otros Bancos' },
  { value: '40021', name: 'HSBC', category: 'Más Usados' },
  { value: '40155', name: 'ICBC', category: 'Otros Bancos' },
  { value: '40036', name: 'INBURSA', category: 'Otros Bancos' },
  { value: '90902', name: 'INDEVAL', category: 'Otros Bancos' },
  { value: '40150', name: 'INMOBILIARIO', category: 'Otros Bancos' },
  { value: '40136', name: 'INTERCAM BANCO', category: 'Otros Bancos' },
  { value: '40059', name: 'INVEX', category: 'Otros Bancos' },
  { value: '40110', name: 'JP MORGAN', category: 'Otros Bancos' },
  { value: '90661', name: 'KLAR', category: 'Bancos Virtuales' },
  { value: '90653', name: 'KUSPIT', category: 'Bancos Virtuales' },
  { value: '90670', name: 'LIBERTAD', category: 'Bancos Virtuales' },
  { value: '90602', name: 'MASARI', category: 'Bancos Virtuales' },
  { value: '90722', name: 'Mercado Pago W', category: 'Bancos Virtuales' },
  { value: '40042', name: 'MIFEL', category: 'Otros Bancos' },
  { value: '40158', name: 'MIZUHO BANK', category: 'Otros Bancos' },
  { value: '90600', name: 'MONEXCB', category: 'Otros Bancos' },
  { value: '40108', name: 'MUFG', category: 'Otros Bancos' },
  { value: '40132', name: 'MULTIVA BANCO', category: 'Otros Bancos' },
  { value: '37135', name: 'NAFIN', category: 'Otros Bancos' },
  { value: '90638', name: 'NU MEXICO', category: 'Bancos Virtuales' },
  { value: '90710', name: 'NVIO', category: 'Bancos Virtuales' },
  { value: '40148', name: 'PAGATODO', category: 'Otros Bancos' },
  { value: '90620', name: 'PROFUTURO', category: 'Otros Bancos' },
  { value: '40156', name: 'SABADELL', category: 'Otros Bancos' },
  { value: '40014', name: 'SANTANDER', category: 'Más Usados' },
  { value: '40044', name: 'SCOTIABANK', category: 'Más Usados' },
  { value: '40157', name: 'SHINHAN', category: 'Otros Bancos' },
  { value: '90728', name: 'SPIN BY OXXO', category: 'Bancos Virtuales' },
  { value: '90646', name: 'STP', category: 'Más Usados' },
  { value: '90703', name: 'TESORED', category: 'Otros Bancos' },
  { value: '90684', name: 'TRANSFER', category: 'Otros Bancos' },
  { value: '90656', name: 'UNAGRA', category: 'Otros Bancos' },
  { value: '90617', name: 'VALMEX', category: 'Otros Bancos' },
  { value: '90605', name: 'VALUE', category: 'Otros Bancos' },
  { value: '90608', name: 'VECTOR', category: 'Otros Bancos' },
  { value: '40113', name: 'VE POR MAS', category: 'Otros Bancos' },
  { value: '40141', name: 'VOLKSWAGEN', category: 'Otros Bancos' },
];

const categoryOrder = ["Más Usados", "Bancos Virtuales", "Otros Bancos"];
const sortedBankList = bankList.sort((a, b) => {
  return categoryOrder.indexOf(a.category) - categoryOrder.indexOf(b.category);
});
export const BankList = sortedBankList;
