import React, { useState } from "react";
import styles from "./ModalImage.module.css";
import { XIcon } from "components/icons/x";
type Props = {
  imageUri: string;
  mode?: "link" | "image";
}
const ModalImage = ({ imageUri, mode = "link" }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  
  return (
    <>
      <div onClick={(openModal)} className={styles.thumbnail}>
        {mode === "link" 
          ? ("🔗")
          : <img
              src={`data:image/jpeg;base64,${imageUri}`}
              alt="Full Image"
              className={styles.fullImage}
              style={{ maxHeight: '70vh'}}
            />
        }
      </div>

      {isModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <button onClick={closeModal} className={styles.closeButton}>
              <XIcon width={20} height={20} />
            </button>
            <img
              src={`data:image/jpeg;base64,${imageUri}`}
              alt="Full Image"
              className={styles.fullImage}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ModalImage;
