import { ViewHeaderProps } from '../../types/viewHeader'
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";

export function ViewHeader(props: ViewHeaderProps) {
  const { canGoBack = false, loading, heading, subheading, children } = props
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <>
      <hgroup>
        <h1 style={{ display: 'flex', flexDirection: 'row'}}>
          {canGoBack && <div style={{ margin: '0px 10px' }} onClick={handleGoBack}>
            <FaChevronLeft size={25} cursor={"pointer"} />
          </div>}
          {heading} <span aria-busy={loading}></span>
        </h1>
        <p>{subheading}</p>
      </hgroup>
      {children}
    </>
  )
}
