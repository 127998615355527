import { FinanceContext } from 'components/financeProvider'
import { config } from 'config'
import { useContext, useEffect, useMemo, useState } from 'react'

export function useDepositSSE() {
  const [SSEEvents, SetSSEEvents] = useState(0)
  const { depositEventSource } = useContext(FinanceContext)

  const handleSseEvent = (_: any) => {
    SetSSEEvents(SSEEvents + 1)
  }

  useEffect(() => {
    if (!depositEventSource) return

    depositEventSource.addEventListener(config.SD.DEPOSIT_EVENT, handleSseEvent)
    depositEventSource.addEventListener(
      config.SD.COIN_TRANSFER_EVENT,
      handleSseEvent
    )

    return () => {
      depositEventSource.removeEventListener(
        config.SD.DEPOSIT_EVENT,
        handleSseEvent
      )
      depositEventSource.removeEventListener(
        config.SD.COIN_TRANSFER_EVENT,
        handleSseEvent
      )
    }
  }, [depositEventSource])

  return useMemo(() => ({ SSEEvents }), [SSEEvents])
}
