import { useContext, useEffect, useState } from 'react'
import { AuthContext } from 'components/authProvider'
import { AnalyticsSummaryType, EventList } from 'types/analytics'
import { toast } from 'react-toastify'

export const useSummary = () => {
  const { apiInit } = useContext(AuthContext)
  const [summary, setSummary] = useState<AnalyticsSummaryType | undefined>(undefined);
  const [summaryWindow, setSummaryWindow] = useState<'day' | 'week' | 'month'>('day');
  const [eventList, seteventList] = useState<EventList[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchSummary = async () => {
    try {
      setLoading(true);
      const api = await apiInit()
      const response = await api.post<{ data: AnalyticsSummaryType }>('/analytics/summary', {
        window: summaryWindow,
      });

      if (response.status === 200) {
        const { data: { data } } = response;
        setSummary(data);
        seteventList(data.eventList);
      } else {
        handleError(response.status);
      }

    } catch (e) {
      toast.error('Error de conexión')
    } finally {
      setLoading(false);
    }
  }

  const handleError = (status: number) => {
    if (status === 401) {
      toast.warn('Sesión expirada.');
    } else if (status >= 500) {
      toast.error('Error de servidor, intentá de nuevo en unos minutos.');
    } else {
      toast.error('No pudimos descargar el resumen de analíticas.');
    }
  };

  useEffect(() => {
    fetchSummary()
  }, [summaryWindow])


  return {
    summary,
    eventList,
    loading,
    setSummaryWindow
  }
}
