import { Link } from 'react-router-dom'
import { parseDate } from '../../components/table-utilities/parse-date'
import { COIN_TRANSFER_STATUS, config } from '../../config'
import { Deposit } from '../../types/deposit'
import { TableDepositStatus } from './depositStatus'
import { useEffect, useState } from 'react'
import { CopyableText } from '../../components/CopyableText'
import { TableAction } from './styled'
import ModalImage from 'components/ModalImage'

export function Deposito({ data }: { data: Deposit }) {
  const [editable, setEditable] = useState(false)
  const threeHours = 3 * 60 * 60 * 1000
  const [background, setBackground] = useState('unset')

  useEffect(() => {
    if (
      data.status === config.SD.DEPOSIT_STATUS.PENDING ||
      data.status === config.SD.DEPOSIT_STATUS.UNVERIFIED ||
      data.CoinTransfer.status === COIN_TRANSFER_STATUS.PENDING
    ) {
      setEditable(true)
    }
  }, [data])

  useEffect(() => {
    setBackground(
      data.status !== config.SD.DEPOSIT_STATUS.VERIFIED &&
        data.status !== config.SD.DEPOSIT_STATUS.DELETED &&
        Date.now() - +new Date(data.created_at) > threeHours &&
        data.CoinTransfer?.status === COIN_TRANSFER_STATUS.PENDING
        ? 'color-mix(in oklab, var(--pico-del-color), transparent 50%)'
        : 'unset'
    )
  }, [data])

  return (
    // @ts-ignore
    <tr style={{ '--pico-background-color': background }}>
      <td style={{ lineBreak: 'anywhere' }}>
        <CopyableText content={data.tracking_number} />
      </td>
      <td>{parseDate(data.created_at)}</td>
      <td>{data.Player.username}</td>
      <td>{data.amount ?? '-'}</td>
      <td>
        <TableDepositStatus status={data.status} />
      </td>
      <td>
        {data.CoinTransfer.status === COIN_TRANSFER_STATUS.COMPLETED
          ? parseDate(data.updated_at)
          : 'No'}
      </td>
      <td>{data.cep_ok ? '✅' : '❌'}</td>
      <td>
        {data.image_uri  
          ? <ModalImage imageUri={data.image_uri} />
          : <p>No</p>
        }
      </td>
      <td>
        {editable && (
          <TableAction className="sm">
            <Link to={`../${data.id}/editar`}>Editar&nbsp;➡</Link>
          </TableAction>
        )}
      </td>
    </tr>
  )
}
