import React, { useEffect, useState } from "react";
import { FaChevronRight } from 'react-icons/fa';
import { AnalyticsFilterType, EventList } from "types/analytics";
import DateRangeSelector from "./DateRangeSelector";

interface LyticsFiltersProps {
  filters: AnalyticsFilterType;
  setFilter: <K extends keyof AnalyticsFilterType>(key: K, value: AnalyticsFilterType[K]) => void;
  eventList: EventList[];
}
const LyticsFilter: React.FC<LyticsFiltersProps> = ({
  filters,
  setFilter,
  eventList,
}) => {
  const [showAllSources, setShowAllSources] = useState(false);
  const [sourceSelected, setSourceSelected] = useState<string | undefined >(undefined);
  const [eventsSelected, setEventsSelected] = useState<string[]>([]);
  const [sourceList, setSourceList] = useState<string[]>([]);

  const handleShowAllSources = () => setShowAllSources(true);

  useEffect(() => {
    const list = eventList.map( (item) => item.source );
    setSourceList(showAllSources ? list : list.slice(0, 4));
    if (!sourceSelected) setSourceSelected(list[0]);
  }, [eventList])
  
  useEffect(() => {
    const filterEvents = eventList.find((item) => item.source === sourceSelected)
    setEventsSelected(filterEvents?.events || []);
  }, [sourceSelected])
  

  return (
    <section>
      <article>
        {/* Campo de búsqueda */}
        <h3>Filtrar</h3>
        <input
          type="text"
          value={filters.search}
          placeholder="Buscar"
          onChange={(e) => setFilter("search", e.target.value)}
          className="search-input"
        />
        <div className="flex-container">
          <div className="source-list">
            {sourceList.map((source, index) => (
              <div
                key={index}
                className={`chip ${source === sourceSelected ? 'src-sel' : ''}`}
                onClick={() => setSourceSelected(source)}
              >
                {source}
              </div>
            ))}
          </div>
          {!showAllSources && (
            <span className="chevron-right" onClick={handleShowAllSources}>
              <FaChevronRight size={20} />
            </span>
          )}
        </div>
      </article>
      {/* Selector de eventos */}
      <div className="flex-container">
        <div style={{ flex: 1 }}>
          <select
            id="event-select"
            value={filters.event || ""}
            onChange={(e) => setFilter("event", e.target.value || "")}
            className="event-select"
          >
            <option value="">Seleccionar evento</option>
            {eventsSelected.map((event, index) => (
              <option key={index} value={event}>
                {event}
              </option>
            ))}
          </select>
        </div>

        <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <DateRangeSelector
            value={filters.window}
            paginationEnable
            onChange={(window) => setFilter("window", window)}
            onPageChange={(newPage) => setFilter("windowPage", newPage)}
          />
        </div>
      </div>
    </section>
  );
}

export default LyticsFilter;