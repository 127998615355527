import {
  ChangeEventHandler,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { ViewHeader } from '../../components/ViewHeader'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AuthContext } from '../../components/authProvider'
import { Deposit } from '../../types/deposit'
import { toast } from 'react-toastify'
import { COIN_TRANSFER_STATUS, config } from '../../config'
import { parseDepositStatus } from './utils'
import { Fieldset, Input } from './styled'
import { FormFooter } from '../../components/FormFooter'
import { BankSelect } from '../config/BankAccount/BankSelect'
import { APIResponse } from '../../types/api'
import { CoinTransfer } from '../../types/coinTransfer'
import ModalImage from 'components/ModalImage'

export function EditDeposit() {
  const { depositId } = useParams()
  const { apiInit } = useContext(AuthContext)

  const [loading, setLoading] = useState(false)
  const [deposit, setDeposit] = useState<Deposit>()
  const [sendBtnText, setSendBtnText] = useState('Verificar')
  const [status, setStatus] = useState({
    translatedStatus: '',
    description: ''
  })
  const [sendBtnTooltipText, setSendBtnTooltipText] = useState('')
  const [editable, setEditable] = useState(false)

  function isEditable(deposit?: Deposit) {
    if (!deposit) return false

    return (
      !deposit.CoinTransfer ||
      deposit.CoinTransfer.status === COIN_TRANSFER_STATUS.PENDING ||
      deposit.status === config.SD.DEPOSIT_STATUS.PENDING ||
      deposit.status === config.SD.DEPOSIT_STATUS.UNVERIFIED
    )
  }

  function isPendingVerification(deposit?: Deposit) {
    if (!deposit) return false
    return (
      deposit.status === config.SD.DEPOSIT_STATUS.PENDING ||
      deposit.status === config.SD.DEPOSIT_STATUS.UNVERIFIED
    )
  }

  useEffect(() => {
    if (!deposit) return

    setEditable(isEditable(deposit))
  }, [deposit])

  const trackingNumberRef = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()

  /** Load deposit */
  useEffect(() => {
    apiInit()
      .then((api) => api.get(`/transactions/deposit/${depositId}`))
      .then((res) => {
        if (res.status === 200) {
          const deposit: Deposit = res.data.data[0]
          if (!isEditable(deposit)) {
            toast('Ese depósito no se puede modificar', { type: 'error' })
            return navigate('/depositos')
          }
          setDeposit(res.data.data[0])
          trackingNumberRef.current?.focus()
        } else throw res
      })
  }, [])

  useEffect(() => {
    if (!deposit) return
    if (
      deposit.status === config.SD.DEPOSIT_STATUS.PENDING ||
      deposit.status === config.SD.DEPOSIT_STATUS.UNVERIFIED
    ) {
      setSendBtnText('Guardar')
      setSendBtnTooltipText('Guardar cambios y verificar depósito')
    } else if (
      !deposit.CoinTransfer ||
      (deposit.status === config.SD.DEPOSIT_STATUS.VERIFIED &&
        deposit.CoinTransfer.status === COIN_TRANSFER_STATUS.PENDING)
    ) {
      setSendBtnText('Enviar fichas')
      setSendBtnTooltipText('Cargar fichas en la cuenta del jugador')
    }

    if (deposit) setStatus(parseDepositStatus(deposit.status))
  }, [deposit])

  const markAsVerified = async () => {
    setLoading(true)
    const api = await apiInit()
    try {
      const res = await api.post<APIResponse<Deposit | string>>(
        `/transactions/deposit/${depositId}/set-status`,
        {
          status: config.SD.DEPOSIT_STATUS.VERIFIED
        }
      )
      if (res.status === 200) {
        const deposit = res.data.data as Deposit

        setDeposit(deposit)

        const { translatedStatus } = parseDepositStatus(deposit.status)
        toast(`Deposito ${translatedStatus}`, { type: 'success' })
      } else if (res.status === 400 && res.data.code === 'already_exists') {
        toast('Un depósito con ese número de seguimiento ya existe', {
          type: 'error'
        })
      } else if (
        (res.status > 400 && res.status <= 403) ||
        res.status === 429
      ) {
        toast.error(res.data.data as string)
      } else throw res
    } catch {
      toast('Algo salió mal, por favor volvé a intentar.', {
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  const handleChange: ChangeEventHandler<
    HTMLInputElement | HTMLSelectElement
  > = (e) => {
    setDeposit((prev) => {
      if (!prev) return
      return {
        ...prev,
        [e.target.name]: e.target.value ?? ''
      }
    })
  }

  const continueDepositFlow = async () => {
    try {
      setLoading(true)
      const api = await apiInit()
      const res = await api.post<
        APIResponse<{ deposit: Deposit; coinTransfer: CoinTransfer }>
      >(`/transactions/deposit/${depositId}`, {
        tracking_number: deposit?.tracking_number,
        amount: deposit?.amount,
        date: deposit?.date,
        sending_bank: deposit?.sending_bank
      })

      if (res.status === 200) {
        const depositResult = res.data.data
        const deposit = depositResult.deposit
        deposit.CoinTransfer = depositResult.coinTransfer
        setDeposit(res.data.data.deposit)
        if (
          depositResult.deposit.status === config.SD.DEPOSIT_STATUS.UNVERIFIED
        )
          toast.warn('No se pudo verificar el depósito')
        else if (
          !depositResult.coinTransfer ||
          depositResult.coinTransfer.status === COIN_TRANSFER_STATUS.PENDING
        )
          toast.warn('No se pudo enviar las fichas')
        else toast.success('👍 OK')
      } else if (res.status === 401) toast.warn('Sesión expirada.')
      else if ((res.status > 400 && res.status <= 403) || res.status === 429) {
        // @ts-ignore
        toast.error(res.data.data as string)
      } else if (res.status >= 500)
        toast.error('Error de servidor, intentá de nuevo en unos minutos.')
      else toast.error('No pudimos hacer eso.')
    } catch (e) {
      toast.error('Error de conexión')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <ViewHeader
        loading={loading}
        heading={'Editar depósito'}
        canGoBack
        />
      <div style={{ display: 'flex', flexDirection: 'row'}}>
        {deposit?.image_uri && <div style={{ flex: 1}}>
          <div>
            <label>Recibo:</label>
            <div style={{ marginLeft: 10 }}>
              <ModalImage
                imageUri={deposit?.image_uri!}
                mode='image'
              />
            </div>
          </div>        
        </div>}
        <div style={{ flex: 2}}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Fieldset className="grid">
              <label>
                ID
                <Input
                  type="text"
                  readOnly
                  disabled={true}
                  value={deposit?.id ?? ''}
                />
              </label>
              <label>
                Usuario
                <Input
                  type="text"
                  readOnly
                  disabled={true}
                  value={deposit?.Player.username ?? ''}
                />
              </label>
            </Fieldset>
            <Fieldset className="grid">
              <label>
                Nº de seguimiento
                <input
                  ref={trackingNumberRef}
                  type="text"
                  value={deposit?.tracking_number ?? ''}
                  name="tracking_number"
                  onChange={handleChange}
                  readOnly={!editable}
                />
              </label>
              <label>
                Fecha
                <Input
                  type="date"
                  value={deposit?.date?.split('T')[0] ?? ''}
                  name="date"
                  onChange={handleChange}
                  readOnly={!editable}
                />
                <small>Hora local</small>
              </label>
            </Fieldset>
            <Fieldset className="grid">
              <label>
                Banco
                <BankSelect
                  value={deposit?.sending_bank ?? ''}
                  name="sending_bank"
                  onChange={handleChange}
                  readOnly={!editable}
                />
              </label>
              <label>
                Monto
                <Input
                  type="text"
                  readOnly={!editable}
                  value={deposit?.amount ?? ''}
                />
              </label>
            </Fieldset>
            <label>
              Estado: &nbsp;
              <Input type="text" value={status.translatedStatus} disabled={true} />
              <small
                style={{
                  color: 'var(--pico-muted-color)'
                }}
              >
                {status.description}
              </small>
            </label>
          </form>
        </div>
      </div>
      <FormFooter style={{ marginTop: 'var(--pico-spacing)' }}>
        <Link to=".." style={{ justifySelf: 'flex-start' }}>
          <button
            className="secondary"
            style={{ textWrap: 'nowrap', width: '100%' }}
          >
            {isEditable(deposit) ? 'Cancelar' : 'Volver'}
          </button>
        </Link>
        {isPendingVerification(deposit) && (
          <button
            className="outline"
            data-tooltip="Ya verifiqué el depósito a mano"
            style={{ textWrap: 'nowrap', width: '100%' }}
            onClick={markAsVerified}
          >
            Marcar como verificado
          </button>
        )}
        <button
          data-tooltip={sendBtnTooltipText}
          disabled={!isEditable(deposit) || loading}
          onClick={continueDepositFlow}
          style={{ textWrap: 'nowrap', width: '100%' }}
        >
          {sendBtnText}
        </button>
        </FormFooter>
    </>
  )
}
